.markdown-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.markdown-container h1 {
  font-size: 2.5em;
  margin-bottom: 1em;
}

.markdown-container h2 {
  font-size: 2em;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.markdown-container h3 {
  font-size: 1.5em;
  margin-top: 1.2em;
  margin-bottom: 0.5em;
}

.markdown-container p {
  margin-bottom: 1em;
  line-height: 1.6;
}

.markdown-container ul,
.markdown-container ol {
  margin-left: 2em;
  margin-bottom: 1em;
}

.markdown-container li {
  margin-bottom: 0.5em;
}